export default {
  page: {
    title: {
      base: 'Payment gateway Autopay',
      start: 'Choosing a payment method',
      visadata: 'Visa Mobile',
      continue: 'Continue - Choosing a payment method',
      wait: 'Wait',
      confirmation: 'Payment confirmation',
      thankYou: 'Thanks for the payment',
      error: 'Website error',
      errorNotFound: 'Page Not Found',
      secureBack: 'Loader',
      maintenance: 'Maintenance',
      regulationsApproval: 'Regulations and Privacy Policy',
      toTransferData: 'transfer details',
      sessionTimeout: 'Page after the end of the session',
      paywayError: 'Payment channel unavailable'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Increase font size',
      decreaseFontSize: 'Decrease font size',
      changeContrast: 'Change contrast',
      changeLanguage: 'Change site language',
      skipToContent: 'Skip to content',
      skipToContentLabel: 'Skip to main content',
      logoTitle: 'Site logo'
    },
    sessionTimer: {
      timeOut: 'Session Expired',
      sessionEnd: 'End of session in:',
      label: 'Extend session time',
      button: 'Extend'
    }
  },
  footer: {
    text1: 'Have additional questions about online payments or  our other services? <a title="It opens in a new window" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Go to the Help page</a>',
    text2: {
      part1: 'The controller of the personal data is Autopay',
      part2: '6 Powstańców Warszawy Street, 81-718 Sopot. Providing personal data is voluntary, but necessary to complete the submitted application. Legal basis, purpose, period of personal data processing and User\'s rights as well as other important information concerning the principles of personal data processing are defined in detail in <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Go to the page with the Privacy Policy document (128 KB, PDF)" rel="noreferrer">the Autopay S.A. Privacy Policy</a>'
    },
    linkShowMoreTitle: 'Show more information',
    linkShowMoreText: 'Read more',
    linkShowLessTitle: 'Show less information',
    linkShowLessText: 'Read less'
  },
  paywall: {
    header: 'Choose a payment method',
    info: {
      paywayNotAvailable: 'We\'re sorry, but it looks like your chosen payment method is not possible on your device at this point.',
      applePayNotAvailable: 'We\'re sorry, but it looks like paying with Apple Pay isn\'t possible on your device at this point.',
      googlePayNotAvailable: 'We\'re sorry, but it looks like paying with Google Pay isn\'t possible on your device at this point.'
    },
    paywayGroup: {
      inactiveMessage: 'Payment is currently unavailable',
      notice: {
        novelty: 'NEW',
        promotion: 'PROMOTION',
        recommended: 'RECOMMENDED',
        mastercard: 'QUICKLY AND SAFELY',
        blik: 'BLIKOMANIA – register and win diamonds!',
        visaMobile: 'Pay smoothly and safely'
      },
      bank: {
        many: 'banks',
        one: 'bank',
        two: 'banks'
      },
      wallet: {
        many: 'wallets',
        one: 'wallet',
        two: 'wallets'
      },
      otp: {
        many: 'ways',
        one: 'way',
        two: 'ways'
      },
      blikPayLater: {
        info: 'Find out more'
      }
    },
    paywayList: {
      label: 'Payment type group',
      linkSelectOther: 'Select other'
    },
    paywaySingle: {
      accountAtAnotherBank: 'I have an account at another bank'
    },
    paywaySwitch: {
      methodShortSingle: 'Disposable',
      methodLongSingle: 'One-off payments',
      methodShortAuto: 'Recurring',
      methodLongAuto: 'Recurring payments'
    },
    form: {
      payway: {
        label: 'Choose your payment method'
      },
      card: {
        label: 'Enter card details',
        iframe: {
          title: 'Credit card details form to be completed'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Form with phone number to be completed'
        },
        status: {
          pending: {
            statusText: 'Awaiting confirmation',
            header: 'Confirm payment in the app',
            descriptionText1: 'You can confirm the payment in the Visa Mobile app or a banking app with Visa Mobile activated',
            descriptionText2: 'After confirming the transaction in the app, await the payment result',
            descriptionText3: 'Transaction cancellation in progress',
            cancelButton: 'Cancel payment'
          },
          success: {
            statusText: 'Thank you for making a purchase!',
            descriptionText: 'Payment completed successfully'
          },
          error: {
            statusText: 'Make sure you have sufficient funds on the payment card used for the Visa Mobile payment.',
            descriptionText: 'Payment rejected'
          }
        },
        apps: 'Apps that support Visa Mobile payments',
        help: 'Do you need help?',
        info: 'Learn more',
        secure: 'Secure payment with SSL encryption',
        modal2: {
          header: 'To confirm the payment open the Visa Mobile app or the app of the bank where you have Visa Mobile activated',
          listText1: 'you have Visa Mobile installed or Visa Mobile active in your bank\'s mobile app',
          listText2: 'you have notifications enabled in Visa Mobile or a banking app with Visa Mobile enabled',
          listText3: 'the number you have provided is correct: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Also make sure that...'
        }
      },
      click2pay: {
        loaderText: 'We are looking for your e-mail address<br>in <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Save your card',
          text2: 'Your data will be saved safely in <strong>Mastercard Click to Pay</strong>',
          link: 'Learn more'
        },
        email: {
          labelForClick2Pay: 'Email address for payment notifications'
        },
        cardSelect: {
          selected: 'Selected',
          select: 'Select',
          payOther: 'Enter your card details',
          back: 'Back'
        },
        cardSelected: {
          header: 'Use a card saved in Click to Pay',
          date: 'valid until',
          buttonChange: 'Change'
        },
        codeForm: {
          description: 'We found your email address in Click to Pay.',
          codeSentEmail: 'Enter the one-time code we sent to {email} to log in to your account.',
          codeSentPhone: 'Enter the one-time SMS code that we sent to {phone} to log in to your account.',
          codeLabel: 'Enter code',
          codePlaceholder: 'enter code',
          codeLabelFlow: 'Code',
          codeSendLabel: 'Send new code',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'or',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'email',
          trustedLabel: 'Add this device to your trusted list<br><span>Next time we will recognize this device and you will pay without logging in</span>',
          buttonSubmit: 'Next',
          buttonChangeCard: 'Enter your card details'
        },
        info: {
          description: 'Card payment is encrypted and secure. The transaction will be authorized using 3DSecure on the bank\'s website.'
        },
        loginForm: {
          emailLabel: 'Enter your email address to log in to Click to Pay',
          emailPlaceholder: 'email address',
          emailLabelFlow: 'Email',
          buttonSubmit: 'Log in',
          buttonBack: 'Back'
        },
        newUserForm: {
          header: 'Save card in Click to Pay',
          description: 'We need a few more details to save your card.',
          emailLabel: 'Enter email address',
          emailPlaceholder: 'email address',
          emailLabelFlow: 'Email',
          countryLabel: 'Country',
          firstnameLabel: 'Name',
          firstnamePlaceholder: 'name',
          firstnameLabelFlow: 'Name',
          lastnameLabel: 'Last name',
          lastnamePlaceholder: 'last name',
          lastnameLabelFlow: 'Last name',
          phoneLabel: 'Phone number',
          phonePlaceholder: 'phone number',
          phoneLabelFlow: 'Phone',
          countryCodePlaceholder: 'code',
          countryCodeLabelFlow: 'Code',
          trustedLabel: 'Add this device to your trusted list<br><span>Next time we will recognize this device and you will pay without logging in</span>',
          termsLabel: 'By continuing, you accept <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Terms of Use</a> and you understand that your data will be processed in accordance with the <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Policy privacy</a> Mastercard.',
          c2pInfo: 'Your email or mobile number will be used to verify you. Message/data rates may apply.',
          buttonSubmit: 'Save and pay',
          buttonBack: 'Pay without saving your card'
        },
        profile: {
          header: 'Log in to Click to Pay',
          buttonChangeEmail: 'Change email address'
        },
        modal: {
          title: 'Click to Pay - information',
          header: 'Pay quickly and securely without re-entering your card details in the new Click to Pay standard',
          text1: 'When paying by card, look for the logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> and pay with your card payment quickly and conveniently.',
          text2: 'Click To Pay is a new card payment standard supported by Visa and Mastercard.',
          text3: 'You can use the card on your Click to Pay account to pay in stores in Poland and abroad, without re-entering your card details.',
          text4: 'The data is stored securely in Visa or Mastercard. All information is fully encrypted.',
          text5: 'During future payments, Click to Pay will recognize your e-mail address, so you can instantly pay with your saved cards.',
          text6: 'You additionally confirm all payments with your bank, which guarantees full security.',
          processError: 'An error occurred while processing your Click to Pay payment. Make a standard card payment.'
        }
      },
      email: {
        label: 'Give your e-mail address',
        flowLabel: 'E-mail',
        tip: 'We need your e-mail address so we can send you messages regarding your payment status.'
      },
      terms: {
        header: 'Consents and statements',
        selectAll: 'Select all',
        fieldNotRequired: 'Optional field',
        partnerMarketingTextLess: 'Would you like to receive email, SMS or phone alerts from us about the best offers, promotions, contests and other interesting events organized by us or our Partners?',
        partnerMarketingTextMore: 'We promise not to spam! If you are bored with our messages, you can withdraw your consent at any time.',
        tradeMarketingTextLess: 'Would you like us to pass on your email or phone number to our trusted partners so they can provide you with interesting information and offers?',
        tradeMarketingTextMore: 'We ensure that our Partners are reputable companies who, like us, don\'t like spamming, and you can revoke your consent at any time.'
      },
      termsLabelDefault: 'I have read and agree to the <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Download the terms of service file" rel="noreferrer">Terms of payment service</a> and the <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Download the Autopay S.A privacy policy file">Privacy Policy</a>. I want the service to be performed immediately, and in the event of withdrawal from the contract, I know that I will not receive reimbursement of the costs incurred for the services provided, at my request, prior to my withdrawal from the contract.',
      termsReccuring: 'If you wish to make a payment, please accept the <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Download the terms of service file" rel="noreferrer">Terms of service</a> and <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Download the Autopay S.A. privacy policy file">Privacy Policy</a> Autopay S.A, consent to immediate payment service and consent to charge my payment card.',
      termsReccuringMore1: 'I consent for the service to be provided immediately and I acknowledge that in the event of withdrawal from the contract, I will not be reimbursed for any costs incurred for the services provided at my request prior to my withdrawal from the contract.',
      termsReccuringMore2: 'I hereby consent to my payment card being charged on a recurring basis by Autopay S.A. to make the transaction for the benefit of {receiverName}. The terms of recurring card charge are defined by the <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Download the terms of service file" rel="noreferrer">Terms of payment service</a>. I hereby declare that I know the periods, frequency and rules of determination of amounts of charge to my payment card as well as the manner of withdrawing this consent, which have been established by me with {receiverName}. I shall contact {receiverName} for any questions regarding the recurring charge to the payment card.',
      mwfClauseLess: 'If you want to make a payment, accept the <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Download the file with the Terms of Service" rel="noreferrer">terms and conditions</a> and <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Download the file with the Autopay S.A. Privacy Policy">privacy policy of Autopay S.A.</a>, consent to start a transaction in your bank through Autopay S.A. and provide details of this transaction to the recipient and request immediate payment service execution.',
      mwfClauseMore: 'I want the service to be provided immediately and I know that I will not receive a refund of the costs incurred in the event of withdrawal from the contract.',
      mwfClauseARIA: 'If you want to make a payment, accept the terms and conditions and privacy policy of Autopay S.A., consent to start a transaction in your bank through Autopay S.A. and provide details of this transaction to the recipient and request immediate payment service execution. I want the service to be provided immediately and I know that I will not receive a refund of the costs incurred in the event of withdrawal from the contract. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Download the file with the Terms of Service</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Download the file with the Autopay S.A. Privacy Policy</a>',
      paymentInfo: 'The payment order is submitted via Autopay S.A. with its registered office in  Sopot and  will be executed according to the terms and conditions set by your bank. After  selecting  the bank, you will authorize the payment.',
      changePaywayLink1: 'You can still',
      changePaywayLink2: 'change the form of payment',
      linkMore: 'MORE',
      linkShowMoreTitle: 'Display additional information',
      linkLess: 'LESS',
      linkShowLessTitle: 'Hide additional information',
      submitButton: {
        visaMobile: 'Pay by',
        fastTransfer: 'Generate transfer details',
        mwf: 'I accept and pay',
        pis: 'I accept and begin payment',
        auto: 'Order recurring payment',
        autoPayment: 'Order payment',
        other: 'Pay now',
        orange: 'Confirm',
        c2pNext: 'Next',
        c2pAccept: 'I accept',
        c2pSaveAndPay: 'Save and pay'
      }
    },
    frData: {
      pageTitle: 'Detailed data for the transfer',
      header: 'Order the transfer using the provided  details',
      nrbLabel: 'Recipient account',
      nameLabel: 'Recipient name',
      addressLabel: 'Recipient address',
      titleLabel: 'Transfer name',
      amountLabel: 'Transfer amount',
      timeLabel: 'Execute transaction by',
      linkCopy: 'Copy',
      linkGetPdf: 'Download PDF with data',
      linkGetPdfTitle: 'It opens in a new window',
      linkGoToBank: 'Log in to the bank',
      info: 'Log into your bank account. Copy the details into the transfer. It is important that the details are the same, do not change the amount, the currency or the title, etc.'
    },
    processing: {
      pageTitle: 'Waiting page for transaction processing',
      loaderAnimation: 'Animation displayed during waiting time',
      header: 'We are preparing your payment page.',
      description: 'Please wait a moment...',
      info: {
        header: 'Note!',
        description: 'Do not close the page after completing the transaction - otherwise your money will not be sent to the recipient.'
      },
      loaderText: 'Data transfer in progress.<br>It will take us a maximum of 30  seconds.'
    }
  },
  thankYou: {
    pageTitle: 'Information about the result of the transaction',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Return to partner page'
    },
    negative: {
      header: 'Payment was not processed due to lack of transaction authorization.',
      description: 'If you still want to use the offer of a particular retailer or service provider - go back to their website, select the product or service and make the payment using the method of your choice.'
    },
    pending: {
      header: 'Thank you for ordering payment via payment gateway',
      description: 'Verification of transaction status is in progress. Once the process is complete, you will receive information about the status of your payment in your email inbox.'
    },
    positive: {
      header: 'Thank you, your payment has been processed.',
      description: 'We will send confirmation to the e-mail address you provided.'
    }
  },
  regulationsApproval: {
    header: 'Accept the transaction'
  },
  wait: {
    pageTitle: 'Waiting page'
  },
  sessionTimeout: {
    pageTitle: 'Page after the end of the session',
    header: 'The time for completing the payment has passed',
    description: 'You can return to the partner\'s site at any time, re-order or use the service and make payment using the method of your choice.'
  },
  paywayError: {
    pageTitle: 'The selected payment channel is unavailable',
    header: 'Unfortunately, the selected payment method is currently unavailable.',
    description: 'Please return to the partner page and try again later.'
  },
  loader: {
    defaultAnimation: 'Animation displayed while the page is loading',
    slider: 'Slider with changing texts',
    text1: 'At Autopay, we strive to achieve carbon neutrality. And  so  do you  by using our services.',
    text2: 'At Autopay, we ride bikes to work reducing greenhouse gas emissions into the atmosphere. And so do you by using our services. ',
    text3: 'At Autopay, we are powered by green energy. And so are you when you are using our services.',
    text4: 'At Autopay, we are reducing our paper consumption. And so do you when you are using our services.'
  },
  orderDetails: {
    header: 'Details',
    headerAriaLabel: 'Transaction details',
    amountLabel: 'Amount due',
    receiverLabel: 'Recipient',
    orderLabel: 'Order number',
    amountOryginalCurrencyLabel: 'Amount in {currency}',
    currencyRateLabel: 'Exchange rate',
    feeLabel: 'Transaction handling fee',
    customerNumberLabel: 'Customer number',
    invoiceNumberLabel: 'Invoice number',
    accountNumberLabel: 'Account number',
    paywayLabel: 'Payment method',
    paywayName: 'Online bank transfer',
    linkBack: 'Back to store',
    linkBackTitle: 'Leave the transaction and go back to the shop',
    linkMore: 'show more',
    linkLess: 'show less',
    toggleBtnUnfoldAriaLabel: 'Expand order details',
    toggleBtnFoldAriaLabel: 'Collapse order details'
  },
  error: {
    pageTitle: 'Error page',
    linkBackUrlSet: 'Return to partner\'s site',
    linkBackUrlNotSet: 'Go to help page',
    sessionTimeout: {
      title: 'The time for completing the payment has passed',
      message: 'You can return to the partner\'s site at any time, re-order or use the service and make payment using the method of your choice.'
    },
    notFoundError: {
      pageTitle: 'Error page 404 - page not found',
      title: 'It looks like you sailed too far and failed to find the page you want to visit.',
      message: 'Possible causes: the page does not exist or has been removed, maintenance is in progress, the page address is incorrect, a technical error has occurred.'
    },
    generalError: {
      title: 'Sorry',
      message: 'We are unable to process your transfer at this time.'
    },
    errFieldNotFound: {
      title: 'Sorry',
      message: ''
    },
    errBadClientSource: {
      title: 'Sorry',
      message: ''
    },
    nrParametersError: {
      title: 'Sorry',
      message: 'We are unable to process your transfer at this time.'
    },
    transactionOutdated: {
      title: 'The time to complete the payment has passed.',
      message: 'Order it again.'
    },
    linkValidityTimeOutdated: {
      title: 'The time to complete the payment has passed.',
      message: 'Order it again.'
    },
    transactionValidityTimeOutdated: {
      title: 'The time to complete the payment has passed.',
      message: 'Order it again.'
    },
    multiplyTransaction: {
      title: 'Sorry',
      message: 'The transaction already exists and is awaiting payment.'
    },
    transactionCanceled: {
      title: 'Sorry',
      message1: 'The transaction was cancelled.',
      message2: 'Order it again.'
    },
    multiplyPaidTransaction: {
      title: 'Sorry',
      message: 'The transaction is already paid for.'
    },
    transactionReject: {
      title: 'Sorry',
      message1: 'Oops, something went wrong.',
      message2: 'A fix is underway...'
    },
    bankDisabled: {
      title: 'Sorry',
      message: 'The selected bank is currently unavailable.'
    },
    bankTemporaryMaintenance: {
      title: 'Sorry',
      message: 'We are unable to process your transfer at this time.'
    },
    insufficientStartAmount: {
      title: 'Sorry',
      message: 'The transfer amount limit has been exceeded.'
    },
    startAmountOutOfRange: {
      title: 'Sorry',
      message: 'The transfer amount limit has been exceeded.'
    },
    nonAccountedLimitExceeded: {
      title: 'Sorry',
      message: 'Payment cannot be processed because the seller has reached the monthly sales limit.'
    }
  },
  confirmation: {
    pageTitle: 'Information about the payment process',
    linkBack: 'I\'m coming back to the site',
    linkBackTimeout: 'Return to partner\'s site in ',
    incorrectAmount: {
      header: 'Wrong amount',
      description: 'We did not transfer your payment to the recipient because the amount was incorrect.',
      description2: 'Click the button to return to the page and redo it. Remember not to change any data.'
    },
    multiplePaid: {
      header: 'Double deposit',
      description: 'The recipient received your payment twice. Don\'t worry - you\'ll get your refund.',
      description2: 'Contact the recipient and provide him with payment confirmation.'
    },
    negative: {
      header: 'End of time',
      description: 'We did not transfer your payment to the recipient because the confirmation time has expired.',
      description2: 'Click the button to return to the page and redo it.'
    },
    notFound: {
      header: 'No payment',
      description: 'An error occurred while processing your payment. But nothing is lost.',
      description2: 'Click the button to return to the page and redo it.'
    },
    outdated: {
      header: 'End of time',
      description: 'We did not transfer your payment to the recipient.',
      description2: 'Click the button to return to the page and redo it.'
    },
    outdatedPaidNotRefunded: {
      header: 'End of time',
      description: 'We did not transfer your payment to the recipient. If money has been withdrawn from your account, don\'t worry, you will receive your refund within a few days.',
      description2: 'Click the button to return to the page and redo it.'
    },
    outdatedPaidRefunded: {
      header: 'End of time',
      description: 'We did not transfer your payment to the recipient. If the money was withdrawn from your account, don\'t worry, we\'ve already refunded it.',
      description2: 'Click the button to return to the page and redo it.'
    },
    pending: {
      header: 'In progress',
      description: 'We are processing your payment. We will notify you when it reaches the recipient.'
    },
    positive: {
      header: 'Paid',
      visaVideo: 'Advertising video played after a successful transaction',
      description: 'We have transferred your funds to the recipient.'
    }
  },
  messages: {
    accessForbidden: 'No access',
    internalServerError: 'An unexpected error has occurred. Try again later.',
    validators: {
      required: 'Required field',
      emailRequired: 'Please provide the correct e-mail address',
      emailNotValid: 'Please provide the correct e-mail address',
      emailNotRecognized: 'The e-mail address provided is not registered',
      emailIdentityLookup: 'The email address could not be verified',
      emailLocked: 'The email address provided is temporarily blocked in the Mastercard system',
      paywayRequired: 'To pay, choose a bank',
      creditCardRequired: 'Complete all fields',
      phoneRequired: 'Please enter a valid phone number',
      regulationRequired: 'Accept the Terms and Conditions',
      requiredConsent: 'Consent required',
      codeNotValid: 'The code provided is not valid',
      codeAccountLocked: 'The account has been temporarily blocked in the Mastercard system'
    },
    validatorsAria: {
      required: 'Attention. The form field has not been completed. Required field',
      emailRequired: 'Attention. The email field has not been completed. Please provide the correct e-mail address',
      emailNotValid: 'Attention. Your e-mail address contains an error. Please provide the correct e-mail address',
      emailNotRecognized: 'Attention. The e-mail address provided is not registered.',
      emailIdentityLookup: 'Attention. The email address provided could not be verified.',
      emailLocked: 'Attention. The email address provided is temporarily blocked in the Mastercard system.',
      paywayRequired: 'Attention. Payment has not been selected. Choose a payment method',
      creditCardRequired: 'Attention. Complete all fields with credit card details',
      phoneRequired: 'Attention. The phone number field contains an error. Please enter a valid phone number.',
      codeNotValid: 'Attention. The code provided is not valid.',
      codeAccountLocked: 'Attention. The account has been temporarily blocked in the Mastercard system.'
    },
    success: {
      copy: 'Copied!',
      codeSent: 'The code has been sent!'
    },
    errors: {
      getPdf: 'Failed to download PDF!',
      copy: 'Failed to copy!',
      thankYouUpdate: 'Failed to save e-mail address!',
      groupInactive: 'At this time, it is impossible to make a transfer using the {groupName} group. Please select another payment method.',
      paywayInactive: 'At the moment, for technical reasons, it is impossible to make a transfer from the selected bank. Please select a different bank or payment method.',
      googlePayInactive: 'Unfortunately Google Pay payment is not available on your device.',
      applePayInactive: 'Apple Pay is available to people using Safari and iOS devices.',
      codeNotSent: 'Failed to resend code!',
      codeCounterExceeded: 'The allowed number of attempts has been exceeded. Enter your card details manually or use another payment method.'
    }
  },
  modal: {
    closeButton: 'Close',
    confirmButton: 'I understand'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Recurring payment',
        description: 'Enter the details of the card you will use for payment. Automatic payments will be taken from your card later.',
        labelInitWithRefund: 'Verification Payment',
        labelInitWithRefundOrange: 'Add payment card',
        labelInitWithPayment: 'Card payment',
        descriptionInitWithRefund: 'Enter the details of the card you want to verify for future payments. In the next step, we will charge 1 PLN from the given card to check the correctness of the data. After verification, we will refund the amount.',
        descriptionInitWithPayment: 'Enter the details of the card you will use to make the transaction and at the same time verify it for future payments.'
      },
      blik: {
        label: 'BLIK',
        description: 'Pay instantly by entering BLIK code, no login or registration'
      },
      card: {
        label: 'Card payment',
        description: 'Pay with your bank card'
      },
      pbl: {
        label: 'Online transfer',
        description: 'Choose the bank from which you want to make the payment',
        descriptionVerify: 'Online identity verification',
        descriptionServiceVerify: 'Select the bank that will allow you to verify the on-line service'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Visa Mobile payment'
      },
      wallet: {
        label: 'Virtual wallet',
        description: 'Pay without having to log into online banking'
      },
      installments: {
        label: 'Instalment payment',
        description: ''
      },
      alior: {
        descriptionInfo1: 'Pay for your purchases using convenient installments',
        descriptionInfo2: '(check details)',
        aliorModal: {
          text1: 'Depending on the settings of the website where you make the purchase, you can purchase products in installments in the following variants:',
          text2: '<ul><li>10 installments 0%</li><li>20 installments 0%</li><li>from 3 to 48 installments with a monthly cost of 1% (according to the representative example given for a given transaction)</li></ul>'
        }
      },
      otp: {
        label: 'Pay later',
        description: 'Buy now and pay later',
        descriptionInfo1Mwf: 'Pay later for the bill - once up to 45 days or in several equal installments',
        descriptionInfo1Ecommerce: 'Pay later - once up to 45 days or in several equal installments',
        descriptionInfo2Mwf: 'Information about the credit intermediary and the cost',
        descriptionInfo2Ecommerce: 'Cost information',
        labelPayka: 'Payka',
        descriptionPayka: 'Pay later for the bill – one time up to 30 days or in 4 equal installments <a href="" id="paykaShowInModal">Information about the credit intermediary and the cost</a>',
        descriptionBlikPayLater: 'The service is available at Millenium Bank and VeloBank',
        paykaModalMwf: {
          header1: 'Loan Broker Notice',
          text1: 'Autopay S.A., headquartered in Sopot, acts as a loan broker for Paytree S.A., headquartered in Gdansk, Poland (Lender), authorized to present the loan offer to customers and redirect to the Lender\'s website, including the loan application. For details of the offer, visit: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Cost Details',
          text2: 'Pay later - once up to 45 days or in several equal installments. Cost data for a representative example: Repayment within 45 days APR 0%; Installment repayment APR 86,83%. Details of the offer at: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Cost Details',
          text2: 'Pay later - once up to 45 days or in several equal installments. Cost data for a representative example: Repayment within 45 days APR 0%; Installment repayment APR 86,83%. Details of the offer at: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Buy now, pay in 30 days',
          header2: 'BLIK code',
          text2: 'You enter the BLIK code and we offer you a shopping limit of up to PLN 4,000',
          header3: 'You buy products and pay for them later',
          text3: 'You have time to check if everything suits you',
          header4: 'You pay within 30 days free of charge',
          text4: 'You can also return your purchases within the time specified by the store'
        }
      },
      companyPayments: {
        label: 'Business payment',
        description: 'Postpone your payment date for business purchases by 30 days'
      },
      transfer: {
        label: 'Transfer details',
        description: 'Order a transfer using the provided details'
      },
      other: {
        label: 'Other',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Sorry. We are currently unable to process your transfer. The selected bank is currently unavailable.',
        blockMultipleTransactions: 'Sorry. The transaction already exists and is awaiting payment.',
        blockPaidTransactions: 'Sorry. The transaction has already been paid for. You cannot make the payment again.',
        canceledTransactions: 'The transaction you are trying to pay for has been cancelled and is not available. Please initiate a new transaction.',
        declined: 'Authorization refusal.',
        generalError: 'Temporary connection problem has occurred. We are unable to process your transfer at this time. Please come back later.',
        insufficientStartAmount: 'Sorry. The transfer amount limit has been exceeded.',
        internalServerError: 'Oopsss.. the operation failed:(. We are working on solving the problem. Try again later.',
        nonAccountedLimitExceeded: 'Payment cannot be processed because the seller has reached the monthly sales limit.',
        outdatedError: 'The time to complete the payment has passed..',
        paid: 'The transaction has been completed successfully.',
        paywayLoaded: 'Fast transfer page has been loaded',
        paywaylistLoaded: 'Payment method selection page has been loaded'
      }
    }
  },
  maintenance: {
    text1: 'A New Year\'s cleanup of the Autopay payment gateway is underway, and as a result it is not possible to order payments at this time. We apologize for the inconvenience.',
    text2: 'The gateway will go live again as early as 16  January at 10 a.m.',
    text3: 'Thank you for your understanding.'
  }
}
